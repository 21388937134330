//=============================== TRAVAIL PAUL MARDI 24 OCTOBRE ===============================

import { createMuiTheme } from '@material-ui/core/styles'

//=============================== FONTS ===============================

const mainFont = 'euclid ignited'
const titleFont = 'itc novarese for swarovski'
//const surtitlesFont = 'sans-serif'
const backFont = 'sans-serif'

const white = '#ffffff'
const black = '#000000'
const primaryMain = '#000000'
const secondaryMain = '#F0EEE4' //Swan White #F0EEE4
const grey = '#979797'
const greyLight = '#f4f6f6'
const greyMedium = '#666'
const greyText = '#3b3b3b'
const heroPink = '#FADEE3'

const btnHover = '#f0f0ec'
const transparent = 'transparent'
const warning = '#FCD1D1' //couleur warning

const hpTitle = {
  fontSize: '47px !important',
  lineHeight: '1.4 !important',
  textAlign: 'center',
  fontFamily: `${titleFont}, ${backFont}`,
  fontWeight: 400,
  '@media (max-width: 760px)': {
    fontSize: '21px !important',
  },
}

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  themeFont: {
    main: `${mainFont}`,
    secondary: `${titleFont}`,
  },
  settings: {
    defaultCountry: 'AE',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: `${mainFont}, ${backFont}`,
    h1: {
      fontFamily: `${titleFont}, ${backFont}`,
      fontSize: `50px !important`,
      lineHeight: 1.2,
      fontWeight: 500,
      color: `${black} !important`,

      '@media (max-width: 760px)': {
        fontSize: '36px !important',
      },
    },
    h2: {
      fontFamily: `${titleFont}, ${backFont}`,
      fontSize: `40px !important`,
      lineHeight: 1.2,
      fontWeight: 500,
      color: `${black} !important`,
    },
    h3: {
      fontFamily: `${titleFont}, ${backFont}`,
      fontSize: 30,
      lineHeight: 1.2,
      fontWeight: 700,
    },
    h4: {
      fontFamily: `${titleFont}, ${backFont}`,
      fontSize: `20px !important`,
      lineHeight: 1.25,
      fontWeight: 500,
      color: `${black} !important`,
    },
    h5: {
      fontFamily: `${titleFont}, ${backFont}`,
      fontSize: 14,
      lineHeight: 1.15,
    },
    h6: {
      fontFamily: `${titleFont}, ${backFont}`,
      fontSize: 12,
      lineHeight: 1.15,
    },
    body1: {
      fontFamily: `${mainFont}, ${backFont}`,
    },
    body2: {
      fontFamily: `${mainFont}, ${backFont}`,
    },
    caption: {
      fontFamily: `${mainFont}, ${backFont}`,
      fontSize: 14,
      lineHeight: 0.8,
      fontWeight: 400,
    },
    button: {
      fontFamily: `${mainFont}, ${backFont}`,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.4,
      textTransform: 'none',
    },
  },
  palette: {
    common: {
      black: black, // Refer to Text color in Figma
      white: white,
    },
    background: {
      default: secondaryMain,
      paper: secondaryMain,
    },
    primary: {
      main: primaryMain,
      contrastText: white,
    },
    secondary: {
      main: primaryMain,
      contrastText: white,
    },
    success: {
      main: '#47E169',
    },
    error: {
      main: '#FA3232',
    },
    warning: {
      main: '#D54117',
    },
    divider: black,
    primaryTransparent: {
      main: 'rgba(0, 0, 0, .5)',
      light: 'rgba(0, 0, 0, .1)',
    },
    secondaryTransparent: {
      main: 'rgba(118, 118, 118, 1)',
      light: 'rgba(118, 118, 118, .1)',
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#767677',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: grey,
    greyLight: greyLight,
    greyMedium: greyMedium,
    greyText: greyText,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.banner': {},
        '.header': {
          '&__logo': {
            height: 'auto !important',
            maxWidth: '152px !important',
          },
        },
        '#cart-menu': {
          '& .MuiPaper-root': {},
        },
        '.basket__btn': {
          '&.MuiButton-root': {
            '& .MuiButton-label': {
              '&>div': {},
              '& .basket__icon': {},
            },
          },
        },
        '.page--home': {},
        '.daterange': {
          '&__submit': {},
          '&>.MuiTooltip-popper': {
            display: 'none !important',
          },
        },
        '.hero': {
          '&__home': {
            backgroundColor: `${white} !important`,
            '&>span': {
              top: '60px !important',
            },
            '&-media': {
              backgroundColor: `${white} !important`,
              width: '80% !important',
              minWidth: 'unset !important',
              maxWidth: 'unset !important',
              height: 'auto !important',
              minHeight: 'unset !important',
              maxHeight: '100% !important',
              objectFit: 'contain !important',
              margin: 'auto 0 0 auto !important',
              '@media (max-width: 760px)': {
                width: '100% !important',
              },
            },
          },
          '&--shop': {
            backgroundColor: `${secondaryMain} !important`,
          },
        },
        '.productCard': {},
        '.productCard__add': {
          '& .MuiButtonBase-root': {
            width: '40px !important',
            '& .MuiButton-label': {
              justifyContent: 'flex-start !important',
            },
          },
        },
        '.product': {
          '&__head': {
            flexDirection: 'column !important',
            alignItems: 'flex-start !important',
          },
          '&__title': {
            fontFamily: `${mainFont}, ${backFont}`,
            fontSize: `16px !important`,
            lineHeight: 1.2,
            fontWeight: 600,
          },
          '&__price': {
            fontFamily: `${titleFont}, ${backFont}`,
            fontSize: `21px !important`,
            lineHeight: 1.4,
            fontWeight: 400,
          },
          '&__amount': {
            '& .price': {
              '&__duration': {
                marginLeft: '6px !important',
              },
            },
          },
          '&-accordion': {
            backgroundColor: `${white} !important`,
          },
        },
        '.pack': {},
        '.commitment': {
          backgroundColor: `${white} !important`,
          padding: '8px !important',
          minHeight: 'unset !important',
          '&__title': {
            fontSize: `14px !important`,
          },
          '&__desc': {
            fontSize: `14px !important`,
          },
        },
        '.faq': {
          '&__item': {
            margin: '0px !important',
          },
          '&__question': {
            margin: '0px !important',
            padding: '10px 16px !important',
            fontSize: '16px !important',
            '&[aria-expanded=true]': {},
          },
          '&__btn': {},
          '&__answer': {
            padding: '24px 16px !important',
            fontSize: '16px !important',
          },
          '&-page': {
            backgroundColor: `${secondaryMain} !important`,
            '&__cat': {
              padding: '0px 16px !important',
            },
          },
          '&-wrap': {
            backgroundColor: `${secondaryMain} !important`,
          },
        },
        '.product__area': {},
        '.testimonial': {},
        '.hiw': {
          '&__title': {
            fontFamily: `${titleFont}, ${backFont}`,
            fontSize: `40px !important`,
            lineHeight: 1.2,
            fontWeight: 500,
            color: `${black} !important`,
            textTransform: 'capitalize',

            '@media (max-width: 760px)': {
              fontSize: `32px !important`,
            },
          },
          '&__desc': {
            fontFamily: `${mainFont}, ${backFont}`,
            fontSize: `13px !important`,
            lineHeight: 1.5,
            fontWeight: 400,
            color: `${black} !important`,

            '@media (max-width: 760px)': {
              fontSize: `16px !important`,
            },

            '&>p': {
              margin: '0px !important',
            },
          },
        },
        '.discover': {
          '&__card': {},
          '&__shadow': {
            display: 'flex !important',
            flexDirection: 'column-reverse !important',
            backgroundColor: `transparent !important`,
          },
        },
        '.discover-product': {
          '&__content': {},
        },
        '.sizes': {
          '&__btn': {},
          '&__chip': {},
        },
        '.section-infos': {
          '&__image': {},
          '&__content': {
            '& h2': {
              ...hpTitle,
            },
          },
        },
        '.section-hiw': {
          '&__title': {
            fontFamily: `${titleFont}, ${backFont}`,
            fontSize: `40px !important`,
            lineHeight: 1.4,
            fontWeight: 500,
            color: `${black} !important`,
            padding: '0px !important',
            textAlign: 'center !important',
            '&>p': {
              padding: '0px !important',
              margin: '0px !important',
            },

            '@media (max-width: 760px)': {
              //fontSize: `21px !important`,
            },
          },
          '&__spacing': {
            padding: '32px 0px !important',

            '@media (max-width: 760px)': {
              padding: '16px 0px !important',
            },
          },
        },
        '.section-faq': {
          '@media (max-width: 767.95px)': {
            padding: '16px 0 48px 0px !important',
          },
          '&__title': {
            fontFamily: `${titleFont}, ${backFont}`,
            fontSize: `40px !important`,
            lineHeight: 1.2,
            fontWeight: 500,
            color: `${black} !important`,

            '&+div': {
              padding: '24px 0px !important',
            },
          },
        },
        '.section-testimonials': {
          '&__title': {
            fontFamily: `${titleFont}, ${backFont}`,
            fontSize: `40px !important`,
            lineHeight: 1.2,
            fontWeight: 500,
            color: `${black} !important`,
          },
        },
        '.section-heros': {
          '& .content': {
            '& h2': {
              ...hpTitle,
            },
          },
        },
        '.section-discover': {
          paddingTop: '16px !important',
          '&__subtitle': {
            fontFamily: `${titleFont}, ${backFont}`,
            fontSize: `40px !important`,
            lineHeight: 1.4,
            fontWeight: 500,
            color: `${black} !important`,
            padding: '0px !important',
            textAlign: 'center !important',
            '&>p': {
              padding: '0px !important',
              margin: '0px !important',
            },

            '@media (max-width: 760px)': {
              //fontSize: `21px !important`,
            },
          },
          '&__subtext': {
            ...hpTitle,
          },
          '&__desc': {
            textAlign: 'center !important',
          },
        },
        '.section-menu': {
          padding: '16px 0px !important',
          '& ul': {
            whiteSpace: 'wrap !important',
          },
        },
        '.flexContent': {},
        '.footer': {
          '&__top': {},
          '&__bottom': {
            paddingTop: '32px !important',
          },
          '&__menu': {
            textAlign: 'center !important',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            height: '80px !important',
            maxWidth: '600px !important',
            margin: '0 auto !important',

            '@media (max-width: 760px)': {
              height: 'auto !important',
              flexWrap: 'nowrap',
              alignItems: 'center',
            },
            '&>a': {
              margin: '0 !important',
              fontSize: '14px !important',
              lineHeight: '20px !important',
            },
            '@media (min-width: 760px)': {
              '&>a:nth-child(6)': {
                paddingBottom: '40px !important',
              },
              '&>a:nth-child(7)': {
                paddingBottom: '60px !important',
              },
            },
          },
          '&__bottomRow2': {
            flexDirection: 'row !important',

            '&>div:first-of-type': {
              display: 'none ! important',
            },
          },
        },
        '.page': {
          '&__hero': {
            '& .MuiTypography-root': {},
          },
          '&__container': {
            '& .page-wysiwyg': {
              '& *': {},
            },
          },
        },
        //CSS cree à partir de ici (paul 24/10/2023)
        '.shop': {
          '&__content': {
            backgroundColor: `${secondaryMain} !important`,
          },
        },
        '.shipping-infos': {
          '&__start,&__end': {
            color: `${white} !important`,
            backgroundColor: `${black} !important`,
            '&>p,&>span': {
              color: `${white} !important`,
              backgroundColor: `${black} !important`,
            },
          },
        },
        '.cart': {
          backgroundColor: `${secondaryMain} !important`,
          '&-item': {
            backgroundColor: `${secondaryMain} !important`,
          },
          '&-shipping--absolute': {
            '&>.shipping-infos': {
              '&>.shipping-infos__start,&>.shipping-infos__end': {
                color: `${black} !important`,
                backgroundColor: `transparent !important`,
                '&>p,&>span': {
                  color: `${black} !important`,
                  backgroundColor: `transparent !important`,
                },
              },
            },
          },
        },
        '.basket': {
          '&>div': {
            backgroundColor: `${white} !important`,
            '& .cart,& .cart-item': {
              backgroundColor: `${white} !important`,
            },
          },
        },
        main: {
          backgroundColor: `${secondaryMain} !important`,
        },
        '.warning': {
          '&-adress__container': {
            backgroundColor: `${warning} !important`,
          },
        },
        '.tagline': {
          fontSize: `20px !important`,
          lineHeight: 1.2,
          fontWeight: 300,
          color: `${black} !important`,
        },
        '.account,.account-informatios': {
          backgroundColor: `${secondaryMain} !important`,
        },
        '.date_form': {
          backgroundColor: `transparent !important`,
        },
        '.funnel': {
          '&__rental-summary': {
            '&>h2': {
              '@media (max-width: 760px)': {
                fontSize: `20px !important`,
              },
            },
          },
        },
        '.account': {
          '&__fields': {
            '& select': {
              '-webkit-appearance': 'menulist-button !important',
              //'-webkit-appearance': 'none !important',
              minHeight: '35px !important',
              cursor: 'pointer',
              lineHeight: '20px !important',
            },
          },
        },
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
      },
      label: {},
      sizeSmall: {},
      sizeLarge: {},
      contained: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.4,
        padding: '7px !important',
        borderRadius: 0,
        textTransform: 'none',
        height: '40px',
        width: '230px',
        color: `${white}!important`,
        backgroundColor: `${black} !important`,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: `${black} !important`,
        transition: '.4s ease-in-out',
        '&:hover, &:focus, &:active, &.selected': {
          color: `${black} !important`,
          backgroundColor: `${btnHover} !important`,
          borderColor: `${transparent} !important`,
          boxShadow: 'inset 0 0 0 .1rem #000',
        },
        '&[disabled]': {},
      },
      containedPrimary: {
        '&:hover, &:focus, &:active, &.selected': {},
      },
      outlinedPrimary: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.4,
        padding: '7px !important',
        borderRadius: 0,
        textTransform: 'none',
        height: '40px',
        width: '230px',
        color: `${white}!important`,
        backgroundColor: `${black} !important`,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: `${black} !important`,
        transition: '.4s ease-in-out',
        '&:hover, &:focus, &:active, &.selected': {
          color: `${black} !important`,
          backgroundColor: `${btnHover} !important`,
          borderColor: `${transparent} !important`,
          boxShadow: 'inset 0 0 0 .1rem #000',
        },
        '&[disabled]': {},
      },
      containedSecondary: {
        '&:hover, &:focus, &:active, &.selected': {},
      },
    },
    MuiPaper: {
      root: {},
      rounded: {
        borderRadius: 0,
      },
    },
    MuiSelect: {
      root: {
        color: '#2933E7',
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiChip: {
      root: {},
      label: {},
      sizeSmall: {},
      labelSmall: {},
      colorPrimary: {},
      colorSecondary: {},
    },
  },
})

export default theme
